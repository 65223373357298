import React, { useState } from 'react'
import { graphql } from 'gatsby'
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl'
import copy from 'copy-to-clipboard'

import Layout from '../components/layout'
import { Content } from '../components/styled'
import styled from 'styled-components'
import { ContactQuery } from '../../graphql-types'
import MarkdownRenderer from '../components/MarkdownRenderer'

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoidGFwamF5IiwiYSI6InFRekUwbzAifQ.9KzsxCsGf97yA5mpc2r69A',
})

const officeLocation = [-0.145849, 51.498046] as [number, number]
const officeEntranceLocation = [-0.146052, 51.498064] as [number, number] //Not overlapping the street name
// const officeEntranceLocation = [-0.146052, 51.498134] as [number, number]
const betweenPalaceAndOffice = [-0.145294, 51.498344] as [number, number]

const googleMapsLocationAdjusted = [
  -0.14606354954188847,
  51.49811487712891,
] as [number, number]

const contact = ({
  location,
  data,
}: {
  location: Location
  data: ContactQuery
}) => {
  const [emailText, setEmailText] = useState(
    data.allContentfulContactPage.edges[0].node.email
  )
  return (
    <Layout location={location}>
      <Content>
        <div style={{ padding: '0 2rem 2rem 2rem' }}>
          <MarkdownRenderer
            html={
              data.allContentfulContactPage.edges[0].node.information
                .childMarkdownRemark.html
            }
          />
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              copy(data.allContentfulContactPage.edges[0].node.email)
              setEmailText(emailText + ' (Copied!)')
            }}
          >
            E: {emailText}
          </div>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/citydesigner/"
          >
            LinkedIn
          </a>
        </div>

        <Style>
          <Map
            style="mapbox://styles/salepi/cke2ozlpe14iq1amt3lje8tob"
            containerStyle={{
              height: '80vh',
              width: '100%',
            }}
            zoom={[15]}
            center={betweenPalaceAndOffice}
          >
            <Layer
              type="circle"
              paint={{
                'circle-radius': 8,
                'circle-color': '#E54E52',
                'circle-opacity': 0.8,
              }}
            >
              <Feature coordinates={googleMapsLocationAdjusted} />
            </Layer>
          </Map>
        </Style>
      </Content>
    </Layout>
  )
}

export default contact

const Style = styled.div``

export const pageQuery = graphql`
  query Contact {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulContactPage {
      edges {
        node {
          email
          information {
            childMarkdownRemark {
              html
              htmlAst
            }
          }
        }
      }
    }
  }
`
